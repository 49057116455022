@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.scrollDesign::-webkit-scrollbar {
  width: 12px;
  height: 0.3rem;
}

::-webkit-scrollbar {
  width: 12px;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  /* background-color: white; */
}
.cs-conversation__content {
  display: flex !important;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}

.scrollbar::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #2cb9a8; /* Color of the scrollbar thumb */
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #199486; /* Color of the scrollbar thumb on hover */
}

/* Hide scrollbar up and down arrow icons */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

.payment_modal .ant-modal-content {
  padding: 0px !important;
  /* color: #07ae98 !important; */
}
.cs-conversation__info {
  display: flex;
  gap: 2px;
}
/* ::-webkit-scrollbar {
  width: 12px;
  height: .5rem;  

} */
/* .scroll-container {
  width: 100%;
  overflow-x: auto;  
 
  ::-webkit-scrollbar {
    width: 12px;
    height: .5rem;  

  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-corner {
    background-color: #f1f1f1;
  }
} */
.cs-message--incoming > .cs-message__content-wrapper > .cs-message__content {
  background-color: #f0f0f0; /* Background color */
  color: #333; /* Text color */
}

/* Customizing outgoing message color */
.cs-message--outgoing > .cs-message__content-wrapper > .cs-message__content {
  background-color: #07ae98; /* Background color */
  color: #fff; /* Text color */
}
.cs-conversation-header,
.cs-conversation-header__user-name,
.cs-conversation-header__info,
.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor,
.cs-search,
.cs-search__input,
.cs-conversation--active,
.cs-message-input__content-editor-wrapper {
  background-color: #f7f7f7 !important;
}

.this-is-me {
  height: min(704px, 100% - 40px);
}

#root {
  height: 100vh;
}

* {
  font-family: Poppins;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-form-item-explain-error {
  font-size: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-menu-item-selected > svg {
  color: #07ae98 !important;
}

.ant-menu-item-selected {
  border-right: 3px solid #07ae98;
  border-radius: 0px !important;
}

.ant-menu-item {
  font-weight: 500;
  display: flex !important;
  align-items: center !important;
  gap: 15px !important;
}

@keyframes showhide {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
}

@keyframes showchat {
  from {
    transform: scale(0);
    opacity: 0;
  }
}

.hide {
  display: none;
  animation-name: showhide;
  animation-duration: 1.5s;
  transform: scale(1);
  opacity: 1;
}

.show {
  display: block;
  animation-name: showhide;
  animation-duration: 1s;
  transform: scale(1);
  opacity: 1;
}

.show1 {
  display: block;
  animation-name: showhide;
  animation-duration: 1s;
  transform: scale(1);
  opacity: 1;
}
/* .slick-arrow  {
  display: none !important;
} */

.swal2-confirm {
  background-color: #2cb9a8 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2cb9a8;
  border-color: #2cb9a8;
}
.ant-checkbox-checked:focus .ant-checkbox-inner:focus {
  background-color: #2cb9a8 !important;
  border-color: #2cb9a8 !important;
}
/* .ant-tabs-tab-btn:hover {
  color: #07ae98;
}
.ant-tabs-tab:hover .ant-tabs-tab-active:hover {
  color: #2cb9a8 !important;
} */
